export const USERSTYPES = {
  CLIENT: 'Client',
  MEMBER: 'Member',
  MANAGER: 'Manager',
  ADMIN: 'Admin'
};

export const GROUPS = {
  ADMINACCESSGROUP: [USERSTYPES.ADMIN, USERSTYPES.MANAGER, USERSTYPES.MEMBER],
  CLIENTACCESSGROUP: [USERSTYPES.CLIENT]
};
