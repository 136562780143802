import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../button';
import SearchInput from '../../../input/SearchInput';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../constants/routes';
import { GROUPS } from '../../../../constants/roles';
import scrollbarStyle from '../../../../scrollbar.module.css';
import { fetchOrders, setOrdersList } from '../../../../redux/slices/order';

import plusIcon from '../../../../assets/plus.svg';
import threeDotsIcon from '../../../../assets/threeDots.svg';
import downArrowIcon from '../../../../assets/downArrow.svg';
import noteIcon from '../../../../assets/note.svg';
import Cube from '../../../../assets/CubeWhite.svg';

import { useDispatch, useSelector } from 'react-redux';
import { Popover } from 'antd';
import dayjs from 'dayjs';

import searchIcon from '../../../../assets/SearchGray.svg';
import debounce from 'lodash.debounce';

const OrderTab = ({ closeModal }) => {
  const { userDetails } = useSelector((state) => state?.userDetails);

  const listElement = useRef(null);

  // Sample DATA
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const { ordersList, loading } = useSelector((state) => state?.order);
  const [maxVisiblityCount, setMaxVisiblityCount] = useState(0);

  const [searchText, setSearchText] = useState('');

  const currentUrl = window?.location?.href;
  const params = currentUrl?.split('/')?.pop();

  const handleChangeSerachText = (e) => {
    setSearchText(e.target.value);
  };

  const handleSelectOrder = (selectedOrderId) => {
    closeModal();
    navigation(`${ROUTES.orderDetails}/activity/${selectedOrderId}`);
  };

  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  const debouncedHandleResize = debounce(function handleResize() {
    setMaxVisiblityCount(Math.floor(listElement?.current?.clientHeight / 36));
  }, 200);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [window.innerHeight]);

  useEffect(() => {
    setTimeout(() => {
      debouncedHandleResize();
    }, 100);
  }, []);

  return (
    <div className='flex flex-col h-full'>
      {/* <div className='mb-5'>
        <button
          onClick={() => navigation(`${ROUTES.allOrders}/goToSearch`)}
          className={`flex flex-row items-center rounded-lg bg-secondary-50 p-2 py-[6px] cursor-text w-full`}
        >
          <img src={searchIcon} />

          <span className='text-neutral-600 text-sm ml-2'>Search</span>
        </button>
      </div> */}

      <div className='flex flex-col gap-y-2 border-b border-neutral-600 pb-2.5'>
        <Button
          icon={plusIcon}
          title={'New Order'}
          iconStyle={'mr-2'}
          textStyle={`text-sm`}
          buttonStyle={'rounded-lg'}
          handleClick={() => {
            closeModal();
            navigation(ROUTES.createOrder);
          }}
        />

        <div className='flex flex-row relative w-full'>
          <Button
            icon={Cube}
            title={'View all Orders'}
            iconStyle={'mr-2'}
            textStyle={`text-sm`}
            buttonStyle={'rounded-lg w-full'}
            handleClick={() => {
              closeModal();
              navigation(ROUTES.allOrders);
            }}
          />

          <div className='absolute right-0 flex flex-col h-full justify-center mr-2'>
            <button
              className='bg-white/10 hover:bg-white/20 px-[2.5px] py-1 rounded'
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
                navigation(`${ROUTES.allOrders}/goToSearch`);
              }}
            >
              <img src={searchIcon} className='w-[20px]' />
            </button>
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-y-1 h-full overflow-hidden'>
        <span className='text-white font-semibold mt-2'>Recent Views</span>

        <div
          ref={listElement}
          className='flex flex-col w-full h-full overflow-hidden'
        >
          {ordersList?.length ? (
            ordersList
              ?.slice(0, maxVisiblityCount || 20)
              ?.map((orderItem, index) =>
                GROUPS.ADMINACCESSGROUP.includes(
                  userDetails?.user?.role?.label
                ) ? (
                  <OrderItemInternalUser
                    key={index}
                    orderItem={orderItem}
                    handleSelectOrder={handleSelectOrder}
                    params={params}
                  />
                ) : (
                  <OrderItemClientUser
                    key={index}
                    orderItem={orderItem}
                    handleSelectOrder={handleSelectOrder}
                    params={params}
                  />
                )
              )
          ) : (
            <span className='text-sm text-neutral-50 mt-0.5 font-light'>
              {loading ? 'Loading...' : 'No recent orders'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const OrderItemInternalUser = ({ orderItem, handleSelectOrder, params }) => {
  return (
    <Popover
      {...(window.innerWidth < 640 && { open: false })}
      placement='bottomLeft'
      overlayInnerStyle={{
        padding: 0
      }}
      overlayStyle={{
        borderRadius: 4
      }}
      content={
        <div className='flex flex-col gap-y-1.5 max-w-[200px] overflow-hidden p-2'>
          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Last Visited</span>
            <span className='text-sm text-neutral-800'>
              {dayjs(orderItem?.visitedAt)?.format('DD MMM YYYY hh:mm A')}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>
              Client’s Order Number
            </span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.clientOrderNumber}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Property Address</span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.address}
            </span>
          </div>

          {orderItem?.project && (
            <div className='flex flex-col'>
              <span className='text-xs text-neutral-600'>Project Name</span>
              <span className='text-sm text-neutral-800'>
                {orderItem?.project}
              </span>
            </div>
          )}
        </div>
      }
    >
      <div
        key={orderItem?.id}
        className={`flex flex-row px-1 min-h-9 items-center hover:bg-primary-50/5 ${
          Number(params) === orderItem?.id ? 'bg-primary-50/5' : ''
        }`}
        onClick={() => handleSelectOrder(orderItem?.id)}
      >
        <div className='flex flex-row items-center cursor-pointer '>
          <img src={noteIcon} className='w-[16px] h-[20px]' />
          <span className='ml-2 text-white text-sm select-none'>
            {orderItem?.uniqueID}
          </span>
        </div>
      </div>
    </Popover>
  );
};

const OrderItemClientUser = ({ orderItem, handleSelectOrder, params }) => {
  return (
    <Popover
      {...(window.innerWidth < 640 && { open: false })}
      placement='bottomLeft'
      overlayInnerStyle={{
        padding: 0
      }}
      overlayStyle={{
        borderRadius: 4
      }}
      content={
        <div className='flex flex-col gap-y-1.5 max-w-[200px] overflow-hidden p-2'>
          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Last Visited</span>
            <span className='text-sm text-neutral-800'>
              {dayjs(orderItem?.visitedAt)?.format('DD MMM YYYY hh:mm A')}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>Property Address</span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.address}
            </span>
          </div>

          <div className='flex flex-col'>
            <span className='text-xs text-neutral-600'>
              Tower Title Order Number
            </span>
            <span className='text-sm text-neutral-800'>
              {orderItem?.uniqueID}
            </span>
          </div>
        </div>
      }
    >
      <div
        key={orderItem?.id}
        className={`flex flex-row px-1 min-h-9 items-center hover:bg-primary-50/5 ${
          Number(params) === orderItem?.id ? 'bg-primary-50/5' : ''
        }`}
        onClick={() => handleSelectOrder(orderItem?.id)}
      >
        <div className='flex flex-row items-center cursor-pointer '>
          <img src={noteIcon} className='w-[16px] h-[20px]' />
          <span className='ml-2 text-white text-sm select-none'>
            {orderItem?.clientOrderNumber}
          </span>
        </div>
      </div>
    </Popover>
  );
};

export default OrderTab;
