import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPartnerProfiles } from '../../utils/api-helper';

const initialState = {
  profileList: [],
  loading: false,
  error: null
};

export const fetchProfiles = createAsyncThunk(
  'profile/fetchProfile',
  async (serach) => {
    const response = await getPartnerProfiles(serach);
    return response.data;
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileList: (state, action) => {
      state.profileList = action?.payload;
    }
  },
  extraReducers: {
    [fetchProfiles.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchProfiles.fulfilled]: (state, action) => {
      state.loading = false;
      state.profileList = action.payload;
    },
    [fetchProfiles.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
});
export const { setProfileList } = profileSlice?.actions;

export default profileSlice.reducer;
