import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRecentVisits } from '../../utils/api-helper';

const initialState = {
  ordersList: [],
  loading: false,
  error: null
};

export const fetchOrders = createAsyncThunk('orders/fetchOrders', async () => {
  const response = await getRecentVisits();
  return response.data;
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrdersList: (state, action) => {
      state.ordersList = action?.payload;
    }
  },
  extraReducers: {
    [fetchOrders.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.loading = false;
      state.ordersList = action.payload;
    },
    [fetchOrders.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    }
  }
});
export const { setOrdersList } = orderSlice?.actions;

export default orderSlice.reducer;
