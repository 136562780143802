import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../auth';
import { useSelector } from 'react-redux';
import { setTopBarHeading } from '../../../redux/slices/topBarHeading';
import logo from '../../../assets/fullLogo.svg';
import dashboardIcon from '../../../assets/dasboard.svg';
import profileDashboardIcon from '../../../assets/ProfileDashboard.svg';
import inboxIcon from '../../../assets/inbox.svg';
import noteIcon from '../../../assets/note.svg';
import profileIcon from '../../../assets/profile.svg';
import SquareUpArrow from '../../../assets/squareUpArrow.svg';
import GearIcon from '../../../assets/GearIcon.svg';
import LogOutIcon from '../../../assets/LogOutIcon.svg';
import OrderTab from './components/OrderTab';

import { Popover } from 'react-tiny-popover';
// import { Popover as AntdPopover } from 'antd';
import UserAccount from '../../../pages/UserAccount';
import DashBoardTab from './components/DashBoardTab';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import ProfileTab from './components/ProfileTab';
import { GROUPS } from '../../../constants/roles';

const SideNavBar = () => {
  const navigation = useNavigate();
  const { handleLogout } = useAuth();
  const { userDetails } = useSelector((state) => state?.userDetails);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [userDetailsModalOpen, setUserDetailsModalOpen] = useState(false);
  const [openTabName, setOpenTabName] = useState(null);
  const [isProfilePopOverOpen, setIsProfilePopOverOpen] = useState(false);

  const sideTabList = [
    {
      icon: dashboardIcon,
      elementName: 'dashboard',
      name: 'Quick Access',
      type: 'tab'
    },
    {
      icon: noteIcon,
      elementName: 'orders',
      name: 'Orders',
      type: 'tab'
    },

    ...(GROUPS.ADMINACCESSGROUP.includes(userDetails?.user?.role?.label)
      ? [
          {
            icon: profileDashboardIcon,
            elementName: 'profile',
            name: 'Profile',
            type: 'tab'
          }
        ]
      : []),

    {
      icon: inboxIcon,
      elementName: ROUTES.mapTools,
      name: 'Map Tool',
      type: 'navigation'
    }
  ];

  const toggleIsSideBarOpen = () => {
    setIsSideBarOpen((pre) => !pre);
  };

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn();
      }, ms);
    };
  }

  const handleChangeTab = (name, type) => {
    setIsSideBarOpen(false);

    if (type == 'navigation') {
      setOpenTabName(null);
      navigation(name);
    } else {
      setOpenTabName(name);
    }
  };

  const toggleProfilePopOver = () => {
    setIsProfilePopOverOpen((pre) => !pre);
  };

  const toggleUserDetailsModal = () => {
    setUserDetailsModalOpen((pre) => !pre);
  };

  const handleClickLogout = async () => {
    toggleIsSideBarOpen();
    handleLogout();
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 50);

    window.addEventListener('resize', debouncedHandleResize);

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [dimensions]);

  return (
    <>
      {dimensions.width < 640 && !isSideBarOpen ? (
        <div className='z-[5] flex absolute w-14 h-12 bg-gray-200  rounded justify-center items-center opacity-80'>
          <button onClick={toggleIsSideBarOpen}>
            <img src={SquareUpArrow} className='rotate-90' />
          </button>
        </div>
      ) : (
        <div
          className={`absolute sm:relative bg-secondary-500 w-[60%] sm:w-[222px] flex flex-col h-full ${
            openTabName === null ? 'z-[15]' : 'z-[16]'
          }`}
        >
          {/* Close In Case of Small device */}
          <div className='absolute sm:hidden right-[-2px] top-[15px] rounded p-1 py-0 shadow-md'>
            <button onClick={toggleIsSideBarOpen}>
              <img src={SquareUpArrow} className='-rotate-90' />
            </button>
          </div>

          <div
            onClick={() => {
              navigation(ROUTES.dashbaord);
            }}
            className='flex justify-center mt-6 cursor-pointer'
          >
            <img src={logo} />
          </div>

          <div className='mt-6 flex-1'>
            <SideNavBarTab
              sideTabList={sideTabList}
              openTabName={openTabName}
              handleChangeTab={handleChangeTab}
            />
          </div>

          {/* User Profile Icon and Popover */}

          <Popover
            isOpen={isProfilePopOverOpen}
            align={'start'}
            onClickOutside={() => {
              toggleProfilePopOver();
            }}
            reposition={false}
            positions={['top']}
            content={
              <div className='flex flex-col w-[200px] bg-white divide-y divide-neutral-200 rounded-lg overflow-hidden'>
                <button
                  onClick={() => {
                    setIsSideBarOpen(false);
                    toggleProfilePopOver();
                    toggleUserDetailsModal();
                  }}
                  className='flex flex-row p-2 hover:bg-neutral-200 gap-x-3 items-center'
                >
                  <img src={GearIcon} className='w-6 h-6' />
                  <span className='text-sm mb-0.5'>Account</span>
                </button>

                <button
                  onClick={handleClickLogout}
                  className='flex flex-row p-2 hover:bg-neutral-200 gap-x-3 items-center'
                >
                  <img src={LogOutIcon} className='w-6 h-6' />
                  <span className='text-sm mb-0.5'>Sign out</span>
                </button>
              </div>
            }
            containerStyle={{
              zIndex: 16,
              paddingBottom: '11px'
            }}
          >
            <button
              onClick={toggleProfilePopOver}
              className='flex flex-row mt-1 mb-6 mx-3 items-center'
            >
              <img src={profileIcon} />
              <span className='px-2 text-white text-base'>
                {userDetails?.user?.first_name +
                  ' ' +
                  userDetails?.user?.last_name}
              </span>
            </button>
          </Popover>
        </div>
      )}

      {/* User Account Modal */}
      {userDetailsModalOpen && (
        <UserAccount
          isOpen={userDetailsModalOpen}
          toggleModal={toggleUserDetailsModal}
        />
      )}

      {openTabName !== null && (
        <SideBarContentModal
          isOpen={openTabName !== null}
          onClose={() => {
            setOpenTabName(null);
          }}
          openTabName={openTabName}
        />
      )}
    </>
  );
};

const SideBarContentModal = ({ isOpen, onClose, openTabName }) => {
  const renderModalContent = () => {
    switch (openTabName) {
      case 'dashboard':
        return <DashBoardTab closeModal={onClose} />;
      case 'orders':
        return <OrderTab closeModal={onClose} />;
      case 'profile':
        return <ProfileTab closeModal={onClose} />;
      default:
        <></>;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      // -translate-y-1/2 top-1/2
      className={`flex flex-col max-w-[95%] sm:max-w-[320px] transform -translate-x-1/2 sm:translate-x-0 left-1/2 sm:left-[228px] max-h-full relative`}
      overlayClassName={`fixed top-0 left-0 right-0 bottom-0 bg-black/60 py-2`}
      style={{ overlay: { zIndex: 15 } }}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <div className='rounded-lg w-full bg-secondary-500 overflow-hidden p-2.5'>
        <div className='flex flex-row justify-end text-xs text-primary-100 mb-1 visible sm:hidden '>
          <button onClick={onClose}>Close</button>
        </div>
        {renderModalContent()}
      </div>
    </Modal>
  );
};

const SideNavBarTab = ({ sideTabList, openTabName, handleChangeTab }) => {
  return (
    <div className='flex flex-col gap-y-2 px-2'>
      {sideTabList.map((sideBardItem, index) => (
        <button
          key={index}
          onClick={() => {
            handleChangeTab(sideBardItem?.elementName, sideBardItem?.type);
          }}
          className={`flex flex-row items-center gap-x-2 w-full rounded-md p-1.5 ${
            openTabName === sideBardItem?.elementName
              ? 'bg-gray-100 text-black/80'
              : 'hover:bg-secondary-400 text-white'
          }`}
        >
          <img src={sideBardItem?.icon} />
          <span className='text-sm'>{sideBardItem?.name}</span>
        </button>
      ))}
    </div>
  );
};

export default SideNavBar;
